import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
} from '@mui/material';
// components
import { useSelector } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
/* read redux, import axios */
import variables from '../variables';

const stripePromise = loadStripe(variables.stripeKey);

const TABLE_HEAD = [
  { id: 'name', label: 'Number', alignRight: false },
  { id: 'company', label: 'Register Date', alignRight: false },
  { id: 'role', label: 'Expiration Date', alignRight: false },
  { id: 'code', label: 'Code', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

export default function HistoryPage() {
  const [open, setOpen] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  /* loading */
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  const [activeNumbers, setActiveNumbers] = useState([]);
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  async function getExpirationDate() {
    setLoading(true);
    try {
      await fetch(`${variables.backendUrl}getSubscriptionStatus`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `${user.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setExpirationDate(data.data.current_period_end);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getActiveNumbers = async () => {
    /* convert all code with fetch  */
    try {
      const result = await fetch(`${variables.backendUrl}getActiveNumber`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${user.token}`,
        },
      });

      const data = await result.json();
      setActiveNumbers(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const IsActiveSubscription = async () => {
    /* get request backend */
    /* if subscription is active return true */
    /* else return false */
    await fetch(`${variables.backendUrl}IsSubscriptionActive`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setSubscriptionStatus(true);
          return true;
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* useEffect */
  useEffect(() => {
    getExpirationDate();
    getActiveNumbers();
    IsActiveSubscription();
  }, []);

  return (
    <>
      <Helmet>
        <title> Active Numbers | BizNumber </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Numbers
          </Typography>
          <Button href="/dashboard/products" variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Number
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {/* loading */}

                  {loading === false ? (
                    activeNumbers?.map((number) => (
                      <>
                        <TableRow hover tabIndex={-1}>
                          <TableCell component="th" scope="row" padding="normal">
                            <Typography variant="subtitle2" noWrap>
                              {number.wa_number}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">{new Date(number.wa_sms_date).toLocaleDateString()}</TableCell>

                          <TableCell align="left">
                            {/* convert humand readable expiry date */}
                            {new Date(expirationDate * 1000).toLocaleDateString()}
                            {/* calculate diffrence now and expirationDate(timestamp) which days left */}
                            {/* new line */}
                            <br />(
                            {
                              // 86400 = 1 day
                              Math.floor((expirationDate - Math.floor(Date.now() / 1000)) / 86400)
                            }{' '}
                            days left )
                          </TableCell>

                          <TableCell align="left">{number.wa_sms_code}</TableCell>
                          <TableCell align="left">
                            <Label variant="filled" color={subscriptionStatus === true ? 'success' : 'error'}>
                              {subscriptionStatus === true ? 'Active' : 'Expired'}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            {/* <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton> */}
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}

                  {activeNumbers?.length > 0 && (
                    <TableRow style={{ height: 53 * activeNumbers?.length }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
