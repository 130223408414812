import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';

import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { useSelector, useDispatch } from 'react-redux';
import account from '../../../_mock/account';
/* import iconify */
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

/* import redux neededs */
import { setToken, setUser } from '../../../redux/actions';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Dashboard',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile Settings',
    icon: 'eva:person-fill',
  },
];

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const beLogout = () => {
    setOpen(null);

    localStorage.clear();

    dispatch(setToken(null));
    dispatch(setUser(null));

    navigate('/login', { replace: true });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={store?.user?.photoURL ? store?.user?.photoURL : account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {store?.user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <Link
            /* text decoration none */
            style={{ textDecoration: 'none', color: 'inherit' }}
            to="/dashboard/profile"
          >
            <MenuItem
              /* href link and add icon */
              sx={{ m: 1 }}
            >
              <Iconify icon={'mdi:user'} sx={{ mr: 2 }} />
              <Typography noWrap>Settings</Typography>
            </MenuItem>
          </Link>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={beLogout} sx={{ m: 1 }}>
          <Iconify icon={'mdi:logout'} skx={{ mr: 2 }} />
          <Typography noWrap color={'red'}>
            Logout
          </Typography>
        </MenuItem>
      </Popover>
    </>
  );
}
