/* default variables */
const variables = {
  backendUrl: 'https://backend.biznumber.io/',
  websockketUrl: 'ws://45.55.201.75:4581',
  /* backendUrl: 'http://localhost:5512/',
  websockketUrl: 'ws://localhost:4581', */
  frontendUrl: 'https://panel.biznumber.io/',
  stripeKey:
    'pk_test_51OjoyeB7JUcrpSMTyuMGhzsjbj4YNy8aCXX0AMjOqQ11nNlmCuOzCir9Ge4WLvFVC8irJ3F4rvvAmZlGwRMy0msj00Ajlwt51H',
};
export default variables;
