import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils

import { useSelector } from 'react-redux';
/* import useEffect */
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import variables from '../../../variables';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

/* import redux */

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  padding: '16px',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, cover, price, colors, status, priceSale } = product;
  const [loading, setLoading] = useState(false);
  /* read user redux */
  const user = useSelector((state) => state.user);

  const buyNumber = async () => {
    console.log(price);
    setLoading(true);

    try {
      const result = await fetch(`${variables.backendUrl}buyExtraNumber`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: user.token,
        },
        body: JSON.stringify({
          howMany: price === '$9.99' ? 1 : 5,
        }),
      });
      const response = await result.json();
      console.log(response);

      if (response.status === true) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'You have successfully bought extra number. Please login again to see the changes.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.message,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}
        <StyledProductImg alt={name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* add only text */}
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',

                textDecoration: 'line-through',
              }}
            >
              {fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
          {/* add buy button with style */}
          {/* loading congtrol */}
          {loading && <CircularProgress size={24} />}
          {!loading && (
            <>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  buyNumber();
                }}
              >
                Buy
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
