import { Helmet } from 'react-helmet-async';

import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import Iconify from '../components/iconify';
// sections
import { AppWidgetSummary, AppCGetCode, AppResultCode } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [user, setUser] = useState(useSelector((state) => state.user));
  const [inProgress, setInProgress] = useState(false);
  const [number, setNumber] = useState('');
  const [code, setCode] = useState('');

  console.log('APPPAGE');
  return (
    <>
      <Helmet>
        <title> Dashboard | BizNumber </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <AppWidgetSummary title="Active Numbers" total={0} icon={'ant-design:message-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <AppWidgetSummary
              title="Can Buy Number"
              total={user?.canBuyCount}
              color="warning"
              icon={'ant-design:plus-circle'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {!inProgress && (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                mt: 3,
              }}
            >
              <AppCGetCode
                setNumber={setNumber}
                setCode={setCode}
                changeProgress={setInProgress}
                userCountry={user?.country}
              />
            </Grid>
          )}
          {/* Show only InProgress true */}
          {inProgress && (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                mt: 3,
              }}
            >
              <AppResultCode number={number} code={code} userCountry={user?.country} />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
