import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Alert, AlertTitle, Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { useSelector, useDispatch } from 'react-redux';
import Iconify from '../../../components/iconify';
/* import variables.js */
import variables from '../../../variables';
import { setToken, setUser } from '../../../redux/actions';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  /* redux */

  const checkValidation = () => {
    /* password pass length and email valid type check */

    if (password.length < 3) {
      setShowAlert('Please enter minimum 3 characters');
      return false;
    }
    if (!email.includes('@')) {
      setShowAlert('Please enter a valid email');
      return false;
    }
    return true;
  };

  const handleClick = async () => {
    if (!checkValidation()) {
      return;
    }

    /* navigate('/dashboard', { replace: true }); */
    setLoading(true);
    /* send data to backend */
    try {
      const data = await fetch(`${variables.backendUrl}login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const response = await data.json();

      if (response.status === 'success') {
        dispatch(setToken(response.data.token));
        dispatch(setUser(response.data));
        
        localStorage.setItem('user', JSON.stringify(response.data));
        navigate('/dashboard', { replace: true });
      }
      setShowAlert(response.message);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowAlert(error.message);
    }
  };

  return (
    <>
      {showAlert && (
        <Alert severity="error" sx={{ mb: 3, p: 2 }} closeText="Close" onClose={() => setShowAlert(false)}>
          <AlertTitle>{showAlert}</AlertTitle>
        </Alert>
      )}
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="false"
          required
          type="email"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Iconify icon="eva:email-outline" />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 1 }}>
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
