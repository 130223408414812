import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg viewBox="0 0 409 409" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M266.581 0C307.582 0 328.077 0 350.147 6.97687C374.243 15.7473 393.22 34.7244 401.991 58.8202C408.968 80.8875 408.968 101.389 408.968 142.386V266.016C408.968 307.016 408.968 327.511 401.991 349.582C393.22 373.678 374.243 392.655 350.147 401.422C328.077 408.402 307.582 408.402 266.581 408.402H142.951C101.951 408.402 81.4527 408.402 59.3853 401.422C35.2896 392.655 16.3125 373.678 7.54206 349.582C0.565186 327.511 0.565186 307.016 0.565186 266.016V142.386C0.565186 101.389 0.565186 80.8875 7.54206 58.8202C16.3125 34.7244 35.2896 15.7473 59.3853 6.97687C81.4527 0 101.951 0 142.951 0H266.581Z"
          fill="url(#paint0_linear_70_1174)"
        />
        <path
          d="M63.0204 202.915C63.0126 227.881 69.5644 252.254 82.0204 273.737L61.8255 347.142L137.284 327.446C158.075 338.731 181.484 344.681 205.303 344.69H205.365C283.813 344.69 347.674 281.134 347.707 203.028C347.721 165.175 332.926 129.581 306.053 102.804C279.177 76.0314 243.442 61.2763 205.365 61.2607C126.904 61.2607 63.0528 124.808 63.0215 202.915H63.0204ZM107.957 270.037L105.14 265.585C93.296 246.836 87.0445 225.171 87.0535 202.924C87.0792 138.003 140.15 85.1855 205.41 85.1855C237.013 85.1988 266.713 97.4636 289.052 119.716C311.39 141.971 323.682 171.555 323.674 203.019C323.645 267.939 270.573 320.764 205.365 320.764H205.318C184.086 320.753 163.262 315.076 145.102 304.349L140.78 301.798L96.0018 313.485L107.957 270.036V270.037Z"
          fill="url(#paint1_linear_70_1174)"
        />
        <path d="M189.683 176.819H217.993V148.51H189.683V176.819Z" fill="#FFF069" />
        <path
          d="M232.148 176.819H260.458V162.665C260.458 154.835 254.132 148.51 246.303 148.51H232.148V176.819Z"
          fill="#FFF069"
        />
        <path
          d="M260.458 233.439H232.148V261.749H246.303C254.132 261.749 260.458 255.423 260.458 247.594V233.439Z"
          fill="#FFF069"
        />
        <path d="M217.993 233.439H189.683V261.749H217.993V233.439Z" fill="#FFF069" />
        <path
          d="M175.528 233.439H147.219V247.594C147.219 255.423 153.544 261.749 161.374 261.749H175.528V233.439Z"
          fill="#FFF069"
        />
        <path d="M260.458 190.974H147.219V219.284H260.458V190.974Z" fill="#FFF069" />
        <path
          d="M161.374 148.51C153.544 148.51 147.219 154.835 147.219 162.665V176.819H175.528V148.51H161.374Z"
          fill="#FFF069"
        />
        <g filter="url(#filter0_d_70_1174)">
          <circle cx="297.954" cy="300.215" r="48.6837" fill="white" />
          <path
            d="M288.643 318.256H307.379V327.262H274.893V319.509L291.621 301.938C293.099 300.358 294.265 298.914 295.119 297.605C295.996 296.273 296.435 294.953 296.435 293.644C296.435 291.996 295.812 290.676 294.565 289.683C293.318 288.667 291.725 288.159 289.785 288.159C286.253 288.159 283.078 289.942 280.261 293.508L273.612 287.245C275.297 284.627 277.629 282.528 280.608 280.948C283.609 279.368 286.876 278.578 290.409 278.578C295.119 278.578 298.963 279.944 301.942 282.675C304.943 285.383 306.444 288.848 306.444 293.068C306.444 295.731 305.878 298.135 304.747 300.279C303.615 302.401 302.103 304.443 300.21 306.407L288.643 318.256Z"
            fill="url(#paint2_linear_70_1174)"
          />
          <path
            d="M333.114 286.338V292.405H325.354V300.215H319.237V292.405H311.477V286.338H319.237V278.578H325.354V286.338H333.114Z"
            fill="url(#paint3_linear_70_1174)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_70_1174"
            x="238.132"
            y="251.531"
            width="119.644"
            height="119.644"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11.1382" />
            <feGaussianBlur stdDeviation="5.56912" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_70_1174" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_70_1174" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_70_1174"
            x1="0.565186"
            y1="0"
            x2="0.565186"
            y2="408.402"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#67FF81" />
            <stop offset="1" stopColor="#01B41F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_70_1174"
            x1="204.766"
            y1="347.142"
            x2="204.766"
            y2="61.2607"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_70_1174"
            x1="302.007"
            y1="338.08"
            x2="249.817"
            y2="288.428"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EF878" />
            <stop offset="1" stopColor="#03B521" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_70_1174"
            x1="329.672"
            y1="305.024"
            x2="307.561"
            y2="274.695"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EF878" />
            <stop offset="1" stopColor="#03B521" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
