// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Grid, Card, Typography, Button, CircularProgress } from '@mui/material';
/* add useEffect */
import React, { useEffect, useState } from 'react';
// components
import ReactFlagsSelect from 'react-flags-select';

import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import variables from '../../../variables';
import Iconify from '../../../components/iconify';
/* import swal */
/* read redux */

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppGetCode.propTypes = {};

function AppGetCode({ setNumber, changeProgress, userCountry, setCode }) {
  /* read redux */
  const userToken = useSelector((state) => state.user.token);
  const [useNumber, setUseNumber] = useState('');
  const [useCountry, setUseCountry] = useState(userCountry);
  const [loading, setLoading] = useState(false);

  /* useEffect */
  useEffect(() => {
    console.log(userCountry);
  }, []);

  const startVerification = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${variables.backendUrl}getNumber`, {
        method: 'post',
        body: JSON.stringify({
          country: useCountry,
        }),

        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userToken}`,
        },
      });
      console.log(response);
      /* get response data */
      const data = await response.json();
      /* if response status is 200 */
      if (response.status === 200) {
        console.log(data.data.number);
        if (data.data.number) {
          setNumber(data.data.number);
          setUseNumber(data.data.number);

          changeProgress(true);

          const ws = new WebSocket(`${variables.websockketUrl}`, userToken);

          ws.onopen = () => {
            console.log('connected');
            ws.send(
              JSON.stringify({
                action: 'listenCode',
                number: data.data.number,
                tzid: data.data.tzid,
              })
            );
          };

          ws.onmessage = (event) => {
            console.log(event.data);
            const data = JSON.parse(event.data);
            if (data.code) {
              Swal.fire({
                icon: 'success',
                title: 'Code received',
                text: `Your code is ${data.code}`,
              });
              setCode(data.code);
            }
            if (data.timeout) {
              Swal.fire({
                icon: 'error',
                title: 'Timeout',
                text: `Your code is not received. Please try again. Sometimes some numbers are very busy.`,
                /* when click okey */
                confirmButtonText: 'I understand',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            }

            ws.close();
          };

          ws.onclose = () => {
            console.log('disconnected');
          };
        } else {
          setNumber(data.message);
        }
      } else {
        /* show error */
        console.log(data);
        Swal.fire({
          icon: 'error',
          title: data.message,
          text: 'Please try again or contact support. Sometimes some countries are very busy.',
        });
      }
    } catch (error) {
      console.log(error);
      if (error.message.includes('https')) {
        return;
      }
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    /* add card box white content */
    <Card
      sx={{
        position: 'relative',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        width: '100%',
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5">Get Code</Typography>
          <Typography variant="body2" sx={{ pb: 3 }}>
            Select country and get code simple and fast
          </Typography>

          <ReactFlagsSelect
            menuPortalTarget={document.body}
            searchable
            searchPlaceholder="Search for a country"
            style={{
              width: '100%',
              height: '50px',
              borderRadius: '5px',
              border: '1px solid #3f51b5',
              outline: 'none',
              cursor: 'pointer',
              zIndex: 9999,
            }}
            /* selected country */
            selected={useCountry}
            /* when change */
            onSelect={(code) => {
              setUseCountry(code);
            }}
            autoSize={false}
          />

          {/* add helper text */}
          <Typography variant="body2" sx={{ pt: 3 }}>
            How to get code?
          </Typography>
          <Typography variant="body2" sx={{ pt: 1 }}>
            1- Select your country
          </Typography>
          <Typography variant="body2" sx={{ pt: 1 }}>
            2- Enter phone number to WhatsApp
          </Typography>
          <Typography variant="body2" sx={{ pt: 1 }}>
            3- Paste code to the field
          </Typography>
          <Typography variant="body2" sx={{ pt: 1 }}>
            ⚠️ Warning: If you start verification, should be completed in as soon as possible.
          </Typography>

          <Button
            variant="contained"
            sx={{
              mt: 3,
              width: '100%',
              height: '50px',
              borderRadius: '5px',
            }}
            onClick={() => {
              startVerification();
            }}
            /* disabled if loading */
            disabled={loading}
          >
            {/* if loading show circle */}
            {loading && <CircularProgress size={20} sx={{ mr: 1 }} />}
            Start Verification
            {/* add icon */}
            <Iconify
              icon="bx:bx-right-arrow-alt"
              sx={{
                width: 20,
                height: 20,
                ml: 1,
              }}
            />
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
export default React.memo(AppGetCode);
