import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Alert,
  AlertTitle,
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
} from '@mui/material';

import ReactFlagsSelect from 'react-flags-select';
import Iconify from '../../../components/iconify';
import CreditCard from '../CreditCard';
import variables from '../../../variables';

export default function RegisterForm({
  email: initialEmail,
  country: initialCountry,
  packageType: initialPackage,
  source: initialSource,
}) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [country, setCountry] = useState(initialCountry || '');
  const [selectedPlan, setSelectedPlan] = useState(initialPackage || 'yearly');
  const [showAlert, setShowAlert] = useState(false);

  const [email, setEmail] = useState(initialEmail || '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const monthlyPrice = 14.99;
  const yearlyPrice = 49.99;
  const yearlyDiscount = Math.round(((monthlyPrice * 12 - yearlyPrice) / (monthlyPrice * 12)) * 100);

  const sentForm = async (error, paymentMethod) => {
    setShowAlert(false);
    if (error) {
      setShowAlert(error?.message);
    } else {
      const isValid = validateForm();
      if (isValid) {
        const data = {
          email,
          password,
          country,
          selectedPlan,
          paymentMethod,
          source: initialSource,
        };
        console.log(data);

        setLoading(true);
        fetch(`${variables.backendUrl}registerUser`, {
          method: 'POST',
          headers: {
            'access-control-allow-origin': '*',
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('Success:', data);
            navigate('/login', { replace: true, register: true });
          })
          .catch((error) => {
            console.error('Error:', error);
            setShowAlert(error?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!/\S+@\S+\.\S+/.test(email)) {
      isValid = false;
      setShowAlert('Please enter a valid email address.');
    }

    if (password.length < 4) {
      isValid = false;
      setShowAlert('Password must be at least 4 characters.');
    }

    if (!country) {
      isValid = false;
      setShowAlert('Please select a country.');
    }

    if (!selectedPlan) {
      isValid = false;
      setShowAlert('Please select a subscription.');
    }

    return isValid;
  };

  return (
    <>
      {loading && (
        <>
          <CircularProgress
            color="primary"
            sx={{
              position: 'absolute',
              top: '60%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
            }}
          />
          <Typography variant="h4" gutterBottom>
            Please wait... Check your information
          </Typography>
        </>
      )}

      {!loading && (
        <>
          <Typography variant="h4" gutterBottom>
            Sign up to BizNumber
          </Typography>

          <Typography variant="body2" sx={{ mb: 5 }}>
            Already have an account?{' '}
            <Link href="/login" variant="subtitle2">
              Login
            </Link>
          </Typography>

          {showAlert && (
            <Alert severity="error" sx={{ mb: 3, p: 2 }} closeText="Close" onClose={() => setShowAlert(false)}>
              <AlertTitle>Error</AlertTitle>
              <strong>{showAlert}</strong>
            </Alert>
          )}

          <Stack spacing={3}>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              label="Email address"
              autoComplete="false"
              required
              type="email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="eva:email-outline" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <ReactFlagsSelect
              searchable
              placeholder="Select country that you want to use"
              showOptionLabel
              showSecondarySelectedLabel
              className="flag-select"
              selectedSize={19}
              optionsSize={15}
              selectedType="flat"
              onSelect={(code) => setCountry(code)}
              selected={country}
            />

            <Box sx={{ p: 2 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select a subscription plan</FormLabel>
                <RadioGroup
                  aria-label="subscription plan"
                  name="subscription-plan"
                  value={selectedPlan}
                  onChange={handlePlanChange}
                >
                  <FormControlLabel
                    value="monthly"
                    control={<Radio />}
                    label={`Monthly plan ($${monthlyPrice}/month)`}
                  />
                  <FormControlLabel
                    value="yearly"
                    control={<Radio />}
                    label={`Yearly plan ($${(yearlyPrice / 12).toFixed(0, 2)}/month)`}
                  />
                </RadioGroup>
              </FormControl>
              <Box sx={{ pt: 2 }}>
                <Typography variant="body2">
                  {selectedPlan === 'monthly'
                    ? `You will be charged $${monthlyPrice} per month.`
                    : `You will be charged $${yearlyPrice} per year (${yearlyDiscount}% discount).`}
                </Typography>
              </Box>
            </Box>
          </Stack>

          <div style={{ height: 16 }} />
          <CreditCard sentForm={sentForm} />
        </>
      )}
    </>
  );
}
