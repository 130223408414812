import React from 'react';
import { Box, Button, Grid, TextField, Typography, Alert, AlertTitle, Snackbar } from '@mui/material';
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { LoadingButton } from '@mui/lab';

import { loadStripe } from '@stripe/stripe-js';
/* import variables */
import variables from "../../variables";

const stripePromise = loadStripe(variables.stripeKey);

const Wrapper = (props) => (
  <Elements stripe={stripePromise}>
    <CreditCardInput {...props} />
  </Elements>
);

const CreditCardInput = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Collect the card details
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    }
    console.log('[PaymentMethod]', paymentMethod);
    props.sentForm(error, paymentMethod);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="card-number"
              InputProps={{
                inputComponent: CardNumberElement,
              }}
              fullWidth
              required
            />
          </Grid>
          {/* add one row two column */}

          <Grid item xs={6}>
            <TextField
              id="card-expiry"
              InputProps={{
                inputComponent: CardExpiryElement,
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="card-cvc"
              InputProps={{
                inputComponent: CardCvcElement,
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              By clicking "Register", you agree to our terms and conditions and confirm that you have read our{' '}
              <a href="#">privacy policy.</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
                Register
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const CreditCard = (props) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Wrapper {...props} />
    </Grid>
  </Grid>
);

export default CreditCard;
