import SvgColor from '../../../components/svg-color';
/* import iconify */
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <Iconify icon="mdi:view-dashboard" style={{ fontSize: '1.5rem', fontWeight: 'bold' }} />,
  },
  {
    title: 'Active Numbers',
    path: '/dashboard/history',
    icon: <Iconify icon="mdi:message-processing" style={{ fontSize: '1.5rem', fontWeight: 'bold' }} />,
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: <Iconify icon="mdi:basket-plus" style={{ fontSize: '1.5rem', fontWeight: 'bold' }} />,
  },
  {
    title: 'Support',
    path: '/dashboard/support',
    icon: <Iconify icon="mdi:face-agent" style={{ fontSize: '1.5rem', fontWeight: 'bold' }} />,
  },
];

export default navConfig;
