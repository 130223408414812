import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Card, TextField, Button, CircularProgress } from '@mui/material';

import { useSelector } from 'react-redux';
import variables from '../variables';
/* read token and redux */

// ----------------------------------------------------------------------

export default function SupportPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);

  const [message, setMessage] = useState('');

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const sentMessage = async () => {
    /* validate */
    if (message === '') {
      alert('Please write your message');
      return;
    }
    setLoading(true);

    await fetch(`${variables.backendUrl}createTicket`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token}`,
      },
      body: JSON.stringify({
        ticketMessage: message,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setMessage('');
          alert('Your message sent successfully');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title> Support | BizNumber </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Create Ticket
        </Typography>

        {/* create a TICKET FORM with mui materials elements. Ticket messsage textbox only. If missing library add */}
        <Card sx={{ p: 3, mt: 5 }}>
          {/* your message textbox */}
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            multiline
            rows={4}
            label="Your Message"
            variant="outlined"
          />

          {/* submit button */}
          <Button onClick={sentMessage} variant="contained" sx={{ mt: 3 }} disabled={loading}>
            Submit
            {loading && <CircularProgress size={20} sx={{ ml: 1 }} />}
          </Button>
        </Card>
        <Card
          /* change background color */
          sx={{ p: 3, mt: 5 }}
        >
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            Response time is 1-2 business days. Submitting a duplicate record will not speed up the process.
          </Typography>
        </Card>
      </Container>
    </>
  );
}
