import { Navigate, useRoutes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import store, { persistor } from './redux/crateStore';
import { getToken, getUser } from './redux/selectors';

import BlogPage from './pages/BlogPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import RegisterPage from './pages/RegisterPage';
import HistoryPage from './pages/HistoryPage';
import SupportPage from './pages/SupportPage';
import ProfilePage from './pages/ProfilePage';

// ----------------------------------------------------------------------

export default function Router() {
  const [authenticated, setAuthenticated] = useState(getToken(store.getState()) !== null);
  /* detect authenticated change and reload page */
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const token = getToken(store.getState());
      if (token !== null) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  console.log('authenticated', authenticated);
  console.log('token', getToken(store.getState()));
  console.log('user', getUser(store.getState()));
  const protectedRoutes = [
    {
      path: '/dashboard',
      element: (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <DashboardLayout />
          </PersistGate>
        </Provider>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'history', element: <HistoryPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'support', element: <SupportPage /> },
        { path: 'profile', element: <ProfilePage /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/app" />,
      index: true,
    },
  ];

  const lessRoutes = [
    {
      path: '/',
      element: <Navigate to="/login" />,
      index: true,
    },
    {
      path: 'login',
      element: (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LoginPage />
          </PersistGate>
        </Provider>
      ),
    },
    {
      path: 'register',
      element: (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RegisterPage />
          </PersistGate>
        </Provider>
      ),
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const routes = useRoutes(authenticated ? protectedRoutes : lessRoutes);

  return routes;
}
