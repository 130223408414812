import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks

import { useSearchParams } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm, RegisterForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
  const mdUp = useResponsive('up', 'md');
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email') || '';
  const country = searchParams.get('country') || '';
  const packageType = searchParams.get('package') || '';
  const source = searchParams.get('source') || '';

  return (
    <>
      <Helmet>
        <title> Register | BizNumber </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection
            /* center */
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              BizNumber Membership
            </Typography>
            <img style={{ width: '30%', height: 'auto' }} src="/assets/wanumbers-logo.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <RegisterForm email={email} country={country} packageType={packageType} source={source} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
