// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Grid, Card, Typography, Button, CircularProgress, Chip, Alert, AlertTitle } from '@mui/material';
/* import useEffect */
import React, { useEffect, useState } from 'react';

// components
import ReactFlagsSelect from 'react-flags-select';

import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppResultCode.propTypes = {};

function AppResultCode({ number, userCountry, code }) {
  
  const [timer, setTimer] = useState(180);
  
  useEffect(() => {
    console.log('RESULTPAGE2');
    const interval = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);

    if (timer <= 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, []);

  return (
    /* add card box white content */
    <Card
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        width: '100%',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Get Code</Typography>
          <Typography variant="body2" sx={{ pb: 3 }}>
            Enter this number to WhatsApp and wait for the code
          </Typography>

          {/* show phoneNumber with style. this box include number and copy button icon */}
          {/* number is not defined */}
          {!code && (
            <>
              <Chip label={number} sx={{ mb: 1.5 }} />

              <Iconify
                icon="bx:bx-copy"
                cursor="pointer"
                onClick={async () => {
                  await navigator.clipboard.writeText(number);
                  alert('Copied to clipboard');
                }}
              />
              {/* add country flag */}

              {/* add Loading */}
              <CircularProgress
                /* center and change color */
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  mb: 3,
                }}
              />
              {/* add timer */}
              <Typography variant="body2" sx={{ pb: 3, textAlign: 'center' }}>
                {timer} seconds
              </Typography>
              {/* add warning alert */}
              <Alert severity="info">
                <AlertTitle>Info</AlertTitle>
                When the code is received, it will automatically appear here.
              </Alert>
            </>
          )}
          {code && (
            <>
              <Chip label={code} sx={{ mb: 1.5 }} />
              <Iconify
                icon="bx:bx-copy"
                cursor="pointer"
                onClick={async () => {
                  await navigator.clipboard.writeText(code);
                  alert('Copied to clipboard');
                }}
              />
              {/* copy this code and paste on whatsapp */}
              <Typography variant="body2" sx={{ pb: 3 }}>
                Copy this code and paste on WhatsApp
              </Typography>
            </>
          )}

          {/* add button */}

          {/*  <Button
            variant="contained"
            color="error"
            sx={{
              mt: 3,
              width: '100%',
              height: '50px',
              borderRadius: '5px',
              outline: 'none',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '20px',
              paddingRight: '20px',
              display: 'flex',
            }}
          >
            <Typography variant="body2">Cancel</Typography>
          </Button> */}
        </Grid>
      </Grid>
    </Card>
  );
}
export default React.memo(AppResultCode);
