import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const PRODUCT_NAME = ['1 WhatsApp Number', '5 WhatsApp Number'];
const PRODUCT_OLD_PRICE = ['$14.99', '$65.99'];
const PRODUCT_PRICE = ['$9.99', '$44.99'];

const PRODUCT_COLOR = ['#00AB55', '#000000', '#FFFFFF', '#FFC0CB', '#FF4842', '#1890FF', '#94D82D', '#FFC107'];

// ----------------------------------------------------------------------

const products = [
  {
    id: faker.datatype.uuid(),
    cover: `/assets/images/products/product_1.png`,
    name: PRODUCT_NAME[0],
    price: PRODUCT_PRICE[0],
    priceSale: PRODUCT_OLD_PRICE[0],
    colors: [PRODUCT_COLOR[0]],
    status: 'Verified Guarantee',
    createdAt: faker.date.past(),
  },
  {
    id: faker.datatype.uuid(),
    cover: `/assets/images/products/product_2.png`,
    name: PRODUCT_NAME[1],
    price: PRODUCT_PRICE[1],
    priceSale: PRODUCT_OLD_PRICE[1],
    colors: [PRODUCT_COLOR[1]],
    status: 'Verified Guarantee',
    createdAt: faker.date.past(),
  },
];

export default products;
