import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Container,
  Stack,
  Typography,
  Card,
  TextField,
  Button,
  Avatar,
  Chip,
  Switch,
  Select,
  Checkbox,
  CircularProgress,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
/* card element import */
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

/* read redux  */
import { useSelector } from 'react-redux';
/* import variables */
import Swal from 'sweetalert2';
import { loadStripe } from '@stripe/stripe-js';
import variables from '../variables';

const stripePromise = loadStripe(variables.stripeKey);

// ----------------------------------------------------------------------

const DialogWrapper = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const changeCustomerCard = async (paymentMethod) => {
    try {
      setLoading(true);
      await fetch(`${variables.backendUrl}changeCustomerCard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${props.props.token}`,
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === true) {
            Swal.fire('Success', 'Card updated successfully', 'success');
            props.props.setOpenDialog(false);
          }
        });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={props.props.openDialog} onClose={() => props.props.setOpenDialog(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Update Card</DialogTitle>
      <DialogContent>
        {/* if error, show error */}
        {/* if Loading */}
        {loading && (
          <Alert sx={{ mb: 3 }} severity="info">
            <CircularProgress />
          </Alert>
        )}

        {error && (
          <Alert sx={{ mb: 3 }} severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        )}
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',

                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.props.setOpenDialog(false)}>Cancel</Button>
        <Button
          onClick={async () => {
            if (!stripe || !elements) {
              return;
            }
            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
              type: 'card',
              card: cardElement,
            });
            console.log('[PaymentMethod]', paymentMethod);

            if (error) {
              setError(error);
            } else {
              setError(null);
              changeCustomerCard(paymentMethod);
            }
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function ProfilePage() {
  const [subscriptionStatus, setSubscriptionStatus] = useState(true);
  const [expirationDate, setExpirationDate] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [savedCard, setSavedCard] = useState('');
  /* read user */
  const user = useSelector((state) => state.user);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [openCardDialog, setOpenCardDialog] = useState(false);

  const lookSubscriptionStatus = async () => {
    setLoading(true);
    try {
      await fetch(`${variables.backendUrl}IsSubscriptionActive`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${user.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === true) {
            setSubscriptionStatus(true);
          } else {
            setSubscriptionStatus(false);
          }
        });
    } catch (error) {
      console.log(error);
      setSubscriptionStatus(false);
    } finally {
      setLoading(false);
    }
  };

  const lookSubscription = async () => {
    setLoading(true);
    await fetch(`${variables.backendUrl}getSubscriptionStatus`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setSubscriptionType(data.data.items.data[0].plan.interval === 'month' ? 'Monthly' : 'Yearly');
          setExpirationDate(data.data.current_period_end);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePassword = async () => {
    /* password control firstly */
    if (password.length < 4) {
      alert('Password must be at least 4 characters');
      return;
    }
    setLoading(true);
    await fetch(`${variables.backendUrl}changePassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token}`,
      },
      body: JSON.stringify({
        password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          Swal.fire('Success', 'Password changed successfully', 'success');
          setPassword('');
        }
      })
      .catch((error) => {
        Swal.fire('Error', 'Something went wrong', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const cancelSubscription = async () => {
    setLoading(true);
    await fetch(`${variables.backendUrl}cancelSubscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          Swal.fire('Success', 'Subscription canceled successfully', 'success');
          setSubscriptionStatus(false);
        }
      })
      .catch((error) => {
        Swal.fire('Error', 'Something went wrong', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const resumeSubscription = async () => {
    setLoading(true);
    await fetch(`${variables.backendUrl}resumeSubscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          Swal.fire('Success', 'Subscription resumed successfully', 'success');
          setSubscriptionStatus(true);
        }
      })
      .catch((error) => {
        Swal.fire('Error', 'Something went wrong', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getSavedCards = async () => {
    setLoading(true);
    await fetch(`${variables.backendUrl}getSavedCards`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setSavedCard(data.data.data[0].card);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reSubscribe = async () => {
    setLoading(true);
    await fetch(`${variables.backendUrl}reSubscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          Swal.fire('Success', 'Subscription resumed successfully. Please login again to see the changes', 'success');
          setSubscriptionStatus(true);
        }
      })
      .catch((error) => {
        Swal.fire('Error', 'Something went wrong', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    lookSubscriptionStatus();
    lookSubscription();
    getSavedCards();
  }, []);

  return (
    <>
      {/* set const variable */}

      <Helmet>
        <title> Profile Settings | BizNumber </title>
      </Helmet>
      <Elements stripe={stripePromise}>
        <DialogWrapper
          props={{
            openDialog: openCardDialog,
            setOpenDialog: setOpenCardDialog,
            token: user.token,
          }}
        />
      </Elements>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Settings
          {/* add circle if loading */}
          {loading && <CircularProgress size={24} sx={{ ml: 1, color: 'primary.main' }} />}
        </Typography>
        {/* if loading disabled */}
        {!loading && (
          <>
            {/* create user settings page. I want to change avatar upload image, change password, change email and name inputs. also see subscription status. saved card list and change subscription */}
            <Card sx={{ p: 3, mt: 5 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Typography variant="h6">Profile</Typography>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <TextField disabled fullWidth label="Email" variant="outlined" value={user?.email} />
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  label="Change Password"
                  variant="outlined"
                  type={'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                {/* password length indicator added with style */}
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  {password.length > 0 && password.length < 6 && (
                    <Chip label="Weak" color="error" size="small" sx={{ mr: 1 }} />
                  )}
                  {password.length >= 6 && password.length < 10 && (
                    <Chip label="Medium" color="warning" size="small" sx={{ mr: 1 }} />
                  )}
                  {password.length >= 10 && <Chip label="Strong" color="success" size="small" sx={{ mr: 1 }} />}
                </Typography>
              </Stack>
              {/* add save button */}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 3 }}
                onClick={changePassword}
              >
                <Button variant="contained">Save</Button>
              </Stack>
            </Card>
            {/* subscription card section include; subscription status, card information, expration date, subscription type monthly or yearly, cancel subscription */}
            <Card sx={{ p: 3, mt: 5 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Typography variant="h6">Subscription</Typography>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  <Chip
                    label={subscriptionStatus ? 'Active' : 'Inactive'}
                    color={subscriptionStatus ? 'success' : 'error'}
                    size="small"
                    sx={{ mr: 1 }}
                  />
                  {subscriptionStatus ? 'Your subscription is active' : 'Your subscription is inactive'}
                </Typography>
              </Stack>
              {/* renewal expiration date added */}
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  Renewal Expiration Date
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  {new Date(expirationDate * 1000).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Typography>
              </Stack>

              {/* subscription duration. monthly or yearly */}
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  Subscription Duration
                </Typography>
                {/* add only as a text */}
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  {subscriptionType}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                {/* IF subscriptionStatus */}
                {subscriptionStatus ? (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      /* confirm yes or no button added */
                      Swal.fire({
                        title: 'Are you sure?',
                        text: 'You will lose access to all your data and will not be able to recover it.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, cancel it!',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          cancelSubscription();
                        }
                      });
                    }}
                  >
                    Cancel Subscription
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      reSubscribe();
                    }}
                  >
                    ReSubscribe Again
                  </Button>
                )}
              </Stack>
              {/* only subscriptionStatus is active */}
              {subscriptionStatus && (
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  <Typography variant="body2" sx={{ color: 'text.primary' }}>
                    If you cancel your subscription, you will lose access to all your data and will not be able to
                    recover it.
                  </Typography>
                </Alert>
              )}
            </Card>

            {/* saved credit card sections. show secret **** 123 format card and User can be added a new credit card area */}
            <Card sx={{ p: 3, mt: 5 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Typography variant="h6">Saved Credit Cards</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  <Chip label={savedCard?.brand} color="success" size="small" sx={{ mr: 1 }} />
                  **** **** ****
                  {savedCard?.last4}
                </Typography>
                {/* add last expiry date */}

                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  {`${savedCard?.exp_month}/${savedCard?.exp_year}`}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 3 }}
                onClick={() => setOpenCardDialog(true)}
              >
                <Button variant="contained">Change Card</Button>
              </Stack>
            </Card>
          </>
        )}
      </Container>
    </>
  );
}
